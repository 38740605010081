import React, { useState, useEffect } from 'react';
import '@styles/react/pages/page-profile.scss';
import '@styles/react/apps/app-users.scss';
import { Row, Col } from 'reactstrap';
import ProfileHeader from './ProfileHeader';
import {useAuth, useLoader} from "../../hooks";
import {Network, Urls,config} from "../../apiConfiguration";
import ProfileAbout from "../staff/profile/ProfileAbout";
import {useParams} from "react-router-dom";
import {showErrorMessage} from "../../components";
import TeamCard from "../staff/profile/TeamCard";
const DetailView = () => {
    const params = useParams();
    const { setLoading } = useLoader();
    const [data, setData] = useState(null);
    const [apiCall, setApiCall] = useState(false);
    const { user } = useAuth();

    const getProfileDetail = async () => {
        setLoading(true);
        const response = await Network.get(
            Urls.getEmployeeDetails(params.employeeId),
            (
                await config()
            ).headers
        );
        setLoading(false);
        if (!response.ok) return showErrorMessage(response.data.message);
        setData(response.data.user);
    };
    useEffect(() => {
        getProfileDetail();
    }, [apiCall]);
    return (
        <>
            <div id='user-profile'>
                <Row>
                    <Col sm='12'>
                        <ProfileHeader user={data} setApiCall={setApiCall} />
                    </Col>
                </Row>
                <section id='profile-info'>
                    <Row>
                        <Col lg={{ size: 12, order: 1 }} sm={{ size: 12 }} xs={{ order: 2 }}>
                            <ProfileAbout user={data} />
                        </Col>
                        {/*<Col lg={{ size: 12, order: 1 }} sm={{ size: 12 }} xs={{ order: 2 }}>*/}
                        {/*    <TeamCard teams={data?.teams} />*/}
                        {/*</Col>*/}
                    </Row>
                </section>
            </div>
        </>
    );
};

export default DetailView;
