import {Table, Card, CardBody, Input, Button} from 'reactstrap';
import {Edit2, PlusCircle, Trash2} from 'react-feather';
import {useEffect, useState} from 'react';
import {SubTaskModal, TaskModal} from '../modal';
import ReactPaginate from 'react-paginate';
import Arrow from '../arrow';
import {useAuth } from '../../../hooks';
import {
  sumSubTaskTime,
  convertSubTaskTime,
  calculateEfficiency,
  getScreenPermissions,
  createZones
} from '../../../utility/Utils';
import {Network, Urls} from "../../../apiConfiguration";
import {showErrorMessage} from "../../../components";
import EditTaskModal from "../modal/EditTaskModal";

const SubTaskTable = ({
  data,
  projectStatus,
  currentPage,
  totalPages,
  handlePagination,
  currentTask,
  setApiCall,
  showDeleteModal,
  handleTaskStatus,
}) => {
  const [selectedTask, setSelectedTask] = useState(null);
  const [open, setOpen] = useState(false);
  const [task,setTask] = useState(null);
  const [openEditModal, setOpenEditModal] = useState(false)
  const zones = createZones(1, 50);
  const {user} = useAuth();
  const taskPermissions = getScreenPermissions(user?.permissions, "task");
  const subTaskPermissions = getScreenPermissions(user?.permissions, "subTask");
  console.log({taskPermissions});
  console.log({subTaskPermissions});
  console.log({user})

  const calculateTaskUsedTime = (subtasks) => {
    let usedTime = 0;
    subtasks.map((subtask) => {
      usedTime += !subtask?.project_time_sheets ? 0 : sumSubTaskTime(subtask?.project_time_sheets);
    });

    return usedTime;
  };

  const handleEdit = (task)=>{
    setTask(task)
    setOpenEditModal(true)
  }



  return (
    <>

      <SubTaskModal
        calculateUsedTime={calculateTaskUsedTime}
        key={open}
        open={open}
        setOpen={setOpen}
        selectedTask={selectedTask}
        projectStatus={projectStatus}
        setApiCall={setApiCall}
      />
      <Card className='mt-2'>
        <CardBody>
          <Table responsive>
            <thead>
            <th className='p-1'>Actions</th>
            <th className='p-1'>Project Name</th>
            <th className='p-1'>Zone</th>
            <th className='p-1'>Work Code</th>
            <th className='p-1'>Activity</th>

            <th className='p-1'>Editor</th>
            <th className='p-1'>Employee</th>
            <th className='p-1'>Time</th>
            <th className='p-1'>Used Time</th>
            <th className='p-1'>Finished</th>
            <th className='p-1'>Efficiency</th>
            </thead>
            <tbody>
            {data?.map((task, key) => {
              if(user?.role_id !== 14 && task?.task_id !== null) {
                return ;
                }
                const taskUsedTime = calculateTaskUsedTime(task?.sub_tasks);
                const taskEfficiency = calculateEfficiency(
                  task?.time,
                  convertSubTaskTime(taskUsedTime?.toString())
                );

                return (
                  <>
                    <tr key={key}>
                      <td>
                        <Edit2
                            onClick={()=>handleEdit(task)}
                className='cursor-pointer me-1'
                size={20}
              />
                        {user?.role_id !== 14 && (
                            <>
                              {subTaskPermissions?.includes('delete') && (
                                  <Trash2
                                      color='red'
                                      className='cursor-pointer ms-1'
                                      size={15}
                                      onClick={() => {
                                        currentTask(task);
                                        showDeleteModal(true);
                                      }}
                                  />
                              )}
                              {subTaskPermissions?.includes('add') && (
                                  <PlusCircle
                                      size={15}
                                      color='purple'
                                      onClick={() => {
                                        setSelectedTask(task);
                                        setOpen(true);
                                      }}
                                  />
                              )}
                            </>
                        )}


                      </td>
                      <td>{task?.project?.project_name}</td>
                      <td>{task?.zone_number}</td>
                      <td>{task?.work_code?.work_code_name}</td>
                      <td>{task?.work_code?.work_category?.work_category_name}</td>
                      <td>{task?.editor?.name ?? '-'}</td>
                      <td>{task?.employeee?.first_name ?? '-'}</td>
                      <td>{task?.time}</td>
                      <td>{convertSubTaskTime(taskUsedTime?.toString())}</td>
                      <td>
                        <div className='form-switch form-check-success ms-1'>
                          <Input
                              disabled={!((task.editor_id === user.id || task.employee_id === user.id || user.role_name === "Draft Coordinator") && user.role_name !== 'Manager'  )}
                              type='switch'
                              id='switch-success'
                              name='status'
                              checked={task?.status}
                              onChange={(e) => {
                                handleTaskStatus(task?.id, e.target.checked);
                              }}
                          />
                        </div>
                      </td>

                      <td>{taskEfficiency}%</td>
                    </tr>

                    {/* Subtask */}

                    {task?.sub_tasks &&
                        task.sub_tasks.map((subtask, index) => {
                        let totalTime = !subtask?.project_time_sheets
                          ? '0:00'
                          : sumSubTaskTime(subtask?.project_time_sheets);
                        const subTaskEfficiency = calculateEfficiency(
                          subtask?.time,
                          convertSubTaskTime(totalTime?.toString())
                        );

                        return (
                            <tr key={index}>
                              <td>
                                <Arrow/>
                              </td>
                              <td>{task?.project?.project_name}</td>
                              <td>{task?.zone_number}</td>
                              <td>{task?.work_code?.work_code_name}</td>
                              <td>{task?.work_code?.work_category?.work_category_name}</td>
                              <td>-</td>
                              <td>{subtask?.employee?.name}</td>
                              <td>{subtask?.time}</td>
                              <td>{convertSubTaskTime(totalTime.toString())}</td>
                              <td>
                                <div className='form-switch form-check-success ms-1'>
                                  <Input
                                      disabled={!((task.coordinator_id === user.id || task.editor_id === user.id || task.employee_id === user.id) && user.role_name !== 'Manager')}
                                      type='switch'
                                      id='switch-success'
                                      name='status'
                                      checked={subtask?.status}
                                      onChange={(e) => {
                                        handleTaskStatus(subtask?.id, e.target.checked);
                                      }}
                                  />
                                </div>
                              </td>

                              <td>{subTaskEfficiency}%</td>
                            </tr>
                        );
                      })}
                  </>
                );
            })}
            </tbody>
          </Table>
        </CardBody>
      </Card>

      <EditTaskModal
          setApiCall={setApiCall}
          taskObj={task}
          zones={zones}
          projectStatus={projectStatus}
          key={openEditModal}
          open={openEditModal}
          setOpen={setOpenEditModal}
      />

      <ReactPaginate
        previousLabel=''
        nextLabel=''
        onPageChange={(page) => handlePagination(page)}
        pageCount={totalPages}
        breakLabel='...'
        pageRangeDisplayed={2}
        marginPagesDisplayed={2}
        forcePage={currentPage}
        activeClassName='active'
        pageClassName='page-item'
        breakClassName='page-item'
        nextLinkClassName='page-link'
        pageLinkClassName='page-link'
        breakLinkClassName='page-link'
        previousLinkClassName='page-link'
        nextClassName='page-item next-item'
        previousClassName='page-item prev-item'
        containerClassName='pagination react-paginate separated-pagination pagination-sm justify-content-end pe-1 mt-1'
      />
    </>
  );
};

export default SubTaskTable;
