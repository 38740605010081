export default {
  login: '/login',
  viewEmployee: '/employee/view-emplopyee',
  teams: '/teams',
  viewTeam: '/teams/view-team',
  timesheet: '/timesheet/view',
  reviewTimesheet: '/review-timesheet',
  todaysEntry: '/timesheet',
  profile: '/profile',
  viewDetails: '/view-detail',
  thermometer: '/projects/thermometer',
  employee: '/employee',
  employeeForm: '/employee/employee-form',
  editEmployee: '/employee/edit-employee',
  viewEmployee: '/employee/view-employee',
  teams: '/teams',
  viewTeam: '/teams/view-team',
  timemachine: '/time-machine',

  // Reports
  summaryReport: '/time-machine/project-summary-report',
  zoneReport: '/time-machine/zone-report',
  monthReport: '/time-machine/month-to-date-report',
  projectReport: '/time-machine/project-report',
  qaReport: '/time-machine/qa-report',
  billingReport: '/time-machine/billing-report',

  dashboard: '/dashboard',
  projects: '/projects',
  projectForm: '/projects/project-form',
  projectDetail: '/projects/project-detail',
  rolesandpermissions: '/roles-and-permissions',
  newRole: '/roles-and-permissions/new-role',
  courses: '/courses',
  partners: '/partners',
  partnerForm: '/partners/partners-form',
  clients: '/customers',
  positions: '/positions',
  workCategories: '/work-categories',
  workCodes: '/work-codes',
  workClassification: '/work-classifications',
  periodReport: '/time-machine/period-report',
  //Super Admin
  adminLogin: '/admin/login',
  adminForgotPassword: '/admin/forgot-password',
  manageCompany: '/admin/manage-company',
  newCompany: '/admin/manage-company/new-company',
  adminChangePassword: '/admin/reset-password',
  adminResetCode: '/admin/reset-code',

  //Project budget

  projectBudget: '/projects/project-budget',
  editProjectBudget: '/projects/edit-project-budget',

  //timesheet summary
  timesheetSummary: '/timesheet-summary',
  timeSheetPrint: '/timesheet-summary/print',

  //Holidays
  holidays: '/holidays',
  //pagenotfound

  pageNotFound: '/*',

  // color codes

  colorCode: '/color-code',

  //task
  task: '/task',
  trainingCenter:'/training-center',
  heirarchyView : '/heirarchy-view',
  heirarchyDetail : '/heirarchy-detail',
  //performance
  performance: '/task/performance',
};
