import { Card, CardBody, CardHeader, CardTitle } from 'reactstrap';
import { Link } from 'react-router-dom';
import { NavRoutes } from '../../../common';

const TeamCard = ({ teams }) => {
  return (
    <Card className='card-employee-task'>
      <CardHeader>
        <CardTitle tag='h4'>Teams</CardTitle>
        <Link to={NavRoutes.teams}>View All</Link>
      </CardHeader>
      <CardBody>
        {teams?.splice(0, 5).map((team, key) => {
          return (
            <div
              key={key}
              className='employee-task d-flex justify-content-between align-items-center'
            >
              <div className='d-flex'>
                <div className='my-auto'>
                  <h6 className='mb-0'>{team?.team_name}</h6>
                  <small>{team?.total_team_members} members</small>
                </div>
              </div>
            </div>
          );
        })}
      </CardBody>
    </Card>
  );
};

export default TeamCard;
