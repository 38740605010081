import React, { useEffect, useState } from 'react';
import { Card, CardHeader, CardBody, CardTitle } from 'reactstrap';
import OrganizationChart from '@dabeng/react-orgchart';
import { useNavigate } from 'react-router-dom';
import { showErrorMessage } from '../../components';
import { useLoader } from '../../hooks';
import { Network, Urls } from '../../apiConfiguration';

const Hierarchy = () => {
  const [hierarchyData, setHierarchyData] = useState(null);
  const [completeData, setCompleteData] = useState(null); // Stores complete hierarchy data
  const { setLoading } = useLoader();
  const navigate = useNavigate();

  useEffect(() => {
    fetchAllData();
  }, []);

  // Fetch the complete hierarchy data at once
  const fetchAllData = async () => {
    try {
      setLoading(true);
      const response =  await Network.get(Urls.getHeierchy); // Assume an endpoint that returns all data
      if (!response.ok) throw new Error('Failed to fetch data');
      const data = response.data.hierarchy;
    console.log(data);
      // Set the complete data for future use and initially display only a part of it
      setCompleteData(data);

      // Initialize with only direct reportees (children of the root node)
      setHierarchyData(transformInitialHierarchy(data));
      console.log({hierarchyData})
    } catch (error) {
      showErrorMessage('Error fetching hierarchy data');
    } finally {
      setLoading(false);
    }
  };

  // Function to initialize hierarchy with just first-level data (associates and reportees)
  const transformInitialHierarchy = (data) => {
    console.log(data);
    if (!data) return null;

    // Create a simplified hierarchy initially (only top-level nodes)
    return {
      id: data.id,
      name: data.name,
      title: data.title || '',
      children: (data.children || []).map(child => ({
        ...child, // Only show immediate reportees
        children: [] // Initially, do not show any children for reportees
      })),
    };
  };

  // Handle node expansion
  const expandNode = (nodeId) => {
    const updatedData = appendManagerData(hierarchyData, nodeId);
    setHierarchyData({ ...updatedData }); // Update state with expanded data
  };

  // Recursive function to append manager data on expansion (limited to one level)
  const appendManagerData = (currentData, nodeId) => {
    if (!currentData) return null;

    // If we find the node, we append only its immediate children
    if (currentData.id === nodeId) {
      const manager = findManagerDataById(completeData, nodeId); // Get the manager data from completeData
      if (manager && manager.children) {
        currentData.children = manager.children.map(child => ({
          ...child,
          children: [] // Only append the immediate children, leave their children empty
        }));
      }
      return currentData;
    }

    // Recursively look for the node in the children
    if (currentData.children) {
      currentData.children = currentData.children.map(child =>
          appendManagerData(child, nodeId)
      );
    }

    return currentData;
  };


  // Utility function to find the manager's data from the complete data by ID
  const findManagerDataById = (data, id) => {
    if (!data) return null;
    if (data.id === id) return data; // Found the manager

    // Recursively search the children
    if (data.children) {
      for (let child of data.children) {
        const result = findManagerDataById(child, id);
        if (result) return result;
      }
    }
    return null;
  };

  let clickTimeout;

  const clickListener = (node) => {
    // Clear the timeout if it's a double-click
    if (clickTimeout) {
      clearTimeout(clickTimeout);
      clickTimeout = null;

      // Handle double-click: Navigate to the profile
      navigate(`/view-detail/${node?.id}`); // Navigate to detail view
    } else {
      // Handle single-click: Set timeout to expand the node
      clickTimeout = setTimeout(() => {
        expandNode(node.id); // Expand the node if not already expanded
        clickTimeout = null;
      }, 300); // Set timeout to detect double-click, 300ms delay
    }
  };




  return (
      <div>
        <Card>
          <CardHeader>
            <CardTitle>
              Organization Chart:
              <p style={{ color: 'red' }}>Double click profile name to see the profile details</p>
            </CardTitle>


          </CardHeader>
          <CardBody>
          {hierarchyData ? (
                <OrganizationChart
                    onClickNode={(node) => clickListener(node, true)}
                    onDoubleClickNode={(node) => clickListener(node, false)}
                    datasource={hierarchyData}
                />
            ) : (
                <div>Loading hierarchy data...</div>
            )}
          </CardBody>
        </Card>
      </div>
  );
};

export default Hierarchy;
