import React, { useState } from 'react';
import { Card, CardImg, Collapse, Navbar, Button } from 'reactstrap';
import { AlignJustify, Edit, MapPin, Calendar, User } from 'react-feather';
import dayjs from 'dayjs';

// import TimeLine from '../../assets/images/profile/timeline.jpg';
import TimeLine from '../../assets/images/profile/timeline.jpg';
const ProfileHeader = ({ user, setApiCall }) => {

    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => setIsOpen(!isOpen);
    return (
        <>
            <Card className='profile-header mb-2'>
                <CardImg src={TimeLine} alt='User Profile Image' top />
                <div className='position-relative'>
                    <div className='profile-img-container d-flex align-items-center'>
                        <div className='profile-img text-center'>
                            {user?.image ? (
                                <img
                                    className='rounded'
                                    src={user?.image}
                                    alt='Profile Picture'
                                    height='115px'
                                    width='110px'
                                />
                            ) : (
                                <div className='d-flex align-items-center justify-content-center h-100'>
                                    <User size={50} />
                                </div>
                            )}
                        </div>
                        <div className='profile-title ms-3'>
                            <h2 className='text-white'>{user ? user.first_name + ' ' + user.last_name : null}</h2>
                            <p className='text-white'>{user?.employee_position_name}</p>
                        </div>
                    </div>
                </div>
                <div className='profile-header-nav'>
                    <Navbar
                        container={false}
                        className='justify-content-end justify-content-md-between w-100'
                        expand='md'
                        light
                    >
                        <Button color='' className='btn-icon navbar-toggler' onClick={toggle}>
                            <AlignJustify size={21} />
                        </Button>
                        <Collapse isOpen={isOpen} navbar>
                            <div className='profile-tabs d-flex justify-content-between align-items-center flex-wrap mt-1 mt-md-0'>
                                <div className='d-flex align-items-center'>
                                    <div>
                                        <MapPin />
                                        <span className='align-middle ms-1'>{user?.country_name}</span>
                                    </div>
                                    <div className='ms-1'>
                                        <Calendar />
                                        <span className='align-middle ms-1'>
                      {dayjs(user?.start_date).format('DD MMM YY')}
                    </span>
                                    </div>
                                </div>
                                <div>


                                </div>
                            </div>
                        </Collapse>
                    </Navbar>
                </div>
            </Card>
        </>
    );
};

export default ProfileHeader;
