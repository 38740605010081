import { useState, useEffect } from 'react';
import { Modal, ModalHeader, ModalBody, Row, Col, Label, Button, Input } from 'reactstrap';
import Select from 'react-select';
import InputMask from 'react-input-mask';
import * as Yup from 'yup';

import { Network, Urls } from '../../../apiConfiguration';
import { useLoader, useSelectedCompany, useAuth } from '../../../hooks';
import { showErrorMessage, showSuccessMessage } from '../../../components';
import { useFormik } from 'formik';
import { DataTransformer, validTimeEntryCheck } from '../../../utility/Utils';

const EditTaskModal = ({ open, setOpen, zones, projectStatus, setApiCall, taskObj }) => {
    const { setLoading } = useLoader();
    const { company } = useSelectedCompany();
    const { user } = useAuth();
    const [workCategories, setWorkCategories] = useState([]);
    const [employeeLocation, setEmployeeLocation] = useState();
    const [workCodes, setWorkCodes] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [employees, setEmployees] = useState([]);
    const [projects, setProjects] = useState([]);
    const [officeLocations, setOfficeLocations] = useState([]);
    const [timeError, setTimeError] = useState(false);

    const getCategories = async () => {
        setLoading(true);
        const response = await Network.get(Urls.manageWorkCategories(company));
        setLoading(false);
        if (!response.ok) return showErrorMessage(response.data.message);
        const { work_categories } = response.data;
        setWorkCategories(DataTransformer(work_categories, 'work_category_name', 'id').filter((category) => category.value === 5 || category.value === 6));
    };

    const getWorkCodes = async (id) => {
        setLoading(true);
        const response = await Network.get(Urls.workCodesByWorkcategories(company) + id);
        setLoading(false);
        if (!response.ok) return showErrorMessage(response.data.message);
        const { workcodes } = response.data;
        setWorkCodes(DataTransformer(workcodes, 'work_label', 'id'));
    };

    const getEmployee = async (locationId) => {
        setLoading(true);
        const response = await Network.post(Urls.getEmployeeByRole, {
            role: [14, 13],
            location_id: locationId ?? user?.office_location_id,
        });
        setLoading(false);
        if (!response.ok) return showErrorMessage(response.data.message);
        setEmployees(DataTransformer(response.data, 'name', 'id'));
    };

    const getProject = async () => {
        setLoading(true);
        const response = await Network.post(Urls.getProjectWithoutPagination(company), {
            team_id: '',
            client_id: '',
        });
        setLoading(false);
        setProjects(DataTransformer(response.data, 'project_name', 'id'));
    };

    const getOfficeLocation = async () => {
        const response = await Network.get(Urls.getOfficeLocations);
        if (!response.ok) return showErrorMessage(response.data.message);
        setOfficeLocations(DataTransformer(response.data.office_locations, 'name', 'id'));
    };

    useEffect(() => {
        selectedCategory && getWorkCodes(selectedCategory);
    }, [selectedCategory]);

    useEffect(() => {
        employeeLocation && getEmployee(employeeLocation);
    }, [employeeLocation]);

    useEffect(() => {
        getCategories();
        getEmployee();
        getProject();
        getOfficeLocation();
    }, []);

    console.log("taskOBJ",taskObj)

    const formik = useFormik({
        initialValues: {
            ...taskObj,
        },
        onSubmit: async (data) => {
            if (timeError) return;
            setLoading(true);
            const response = await Network.patch(Urls.updateAllTask(company,taskObj.id), data);
            setLoading(false);
            if (!response.ok) return showErrorMessage(response.data.message);
            showSuccessMessage('Task Edit successfully');
            setApiCall((prev) => !prev);
            setOpen(false);
        },
        validationSchema: Yup.object().shape({
            project_id: Yup.string().required('Project is required'),
            editor_id: Yup.string().required('Editor is required'),
            location_id: Yup.string().required('Location is required'),
            zone_number: Yup.string().required('Zone number is required'),
            status: Yup.string().required('Status is required'),
            work_code_id: Yup.string().required('Work code is required'),
            time: Yup.string().required('Time is required'),
        }),
    });

    useEffect(() => {
        if (taskObj) {
            formik.setValues({
                project_id: taskObj?.project_id,
                editor_id: taskObj?.editor_id,
                location_id:  user?.office_location_id,
                zone_number: taskObj?.zone_number,
                status: taskObj?.status,
                work_category_id: taskObj?.work_code?.work_category_id,
                work_code_id: taskObj?.work_code_id,
                time: taskObj?.time,
            });
            setSelectedCategory(taskObj?.work_code?.work_category_id);
        }
    }, [taskObj]);

    const checkValue = (e) => {
        if (!validTimeEntryCheck(formik.values.time)) {
            formik.setErrors({ time: `Please enter time in 15 minutes interval` });
            setTimeError(true);
            return;
        }
        formik.handleBlur(e);
    };

    const ErrorMessage = ({ name }) => {
        if (!formik.touched[name] && !formik.errors[name]) return null;
        return <div className='text-danger fst-italic fw-bold'>{formik.errors[name]}</div>;
    };

    return (
        <Modal isOpen={open} toggle={() => setOpen(!open)}>
            <ModalHeader className='bg-white' toggle={() => setOpen(!open)}>
                Edit Task
            </ModalHeader>
            <ModalBody>
                <Row>
                    <Col className='mt-1' md={6} sm={6}>
                        <Label>Locations</Label>
                        <Select
                            placeholder='Location'
                            value={officeLocations.filter((i) => i.value === formik.values.location_id)}
                            onChange={({ value }) => {
                                const event = {
                                    target: {
                                        name: 'location_id',
                                        value,
                                    },
                                };
                                formik.handleChange(event);
                                setEmployeeLocation(value);
                            }}
                            options={officeLocations}
                        />
                        <ErrorMessage name='location_id' />
                    </Col>
                    <Col className='mt-1' md={6} sm={6}>
                        <Label>Time</Label>
                        <InputMask
                            onBlur={(e) => {
                                checkValue(e);
                            }}
                            placeholder='00:00'
                            mask='99:99'
                            name='time'
                            maskChar={':'}
                            value={formik.values.time}
                            onChange={(e) => {
                                const inputTime = e.target.value;
                                const [hours, minutes] = inputTime.split(':').map(Number);
                                let convertedHours = hours;
                                let convertedMinutes = minutes;

                                // Check if minutes exceed 59
                                if (minutes > 59) {
                                    const extraHours = Math.floor(minutes / 60);
                                    convertedHours += extraHours;
                                    convertedMinutes = minutes % 60;
                                }
                                const formattedTime = `${convertedHours.toString().padStart(2, '0')}:${convertedMinutes.toString().padStart(2, '0')}`;
                                formik.handleChange({
                                    target: {
                                        name: 'time',
                                        value: formattedTime,
                                    },
                                });
                            }}
                        >
                            {(inputProps) => <Input name='time' {...inputProps} />}
                        </InputMask>
                        <ErrorMessage name='time' />
                    </Col>
                    <Col className='mt-1' md={6} sm={6}>
                        <Label>Projects</Label>
                        <Select
                            placeholder='Project'
                            value={projects.filter((i) => i.value === formik.values.project_id)}
                            onChange={({ value }) => {
                                const event = {
                                    target: {
                                        name: 'project_id',
                                        value,
                                    },
                                };
                                formik.handleChange(event);
                            }}
                            options={projects}
                        />
                        <ErrorMessage name='project_id' />
                    </Col>
                    <Col className='mt-1' md={6} sm={6}>
                        <Label>Status</Label>
                        <Select
                            isDisabled
                            name='status'
                            placeholder='Status'
                            value={projectStatus.filter((i) => i.value === formik.values.status)}
                            onChange={({ value }) => {
                                const event = {
                                    target: {
                                        name: 'status',
                                        value,
                                    },
                                };
                                formik.handleChange(event);
                            }}
                            options={projectStatus}
                        />
                        <ErrorMessage name='status' />
                    </Col>
                    <Col className='mt-1' md={6} sm={6}>
                        <Label>Zone</Label>
                        <Select
                            placeholder='Zone'
                            value={zones.filter((i) => i.value === formik.values.zone_number)}
                            onChange={({ value }) => {
                                const event = {
                                    target: {
                                        name: 'zone_number',
                                        value,
                                    },
                                };
                                formik.handleChange(event);
                            }}
                            options={zones}
                        />
                        <ErrorMessage name='zone_number' />
                    </Col>
                    <Col className='mt-1' md={6} sm={6}>
                        <Label>Work Categories</Label>
                        <Select
                            placeholder='Categories'
                            value={workCategories.filter((i) => i.value === selectedCategory)}
                            onChange={({ value }) => {
                                setSelectedCategory(value);
                            }}
                            options={workCategories}
                        />
                    </Col>
                    <Col className='mt-1' md={6} sm={6}>
                        <Label>Work Codes</Label>
                        <Select
                            isDisabled={!selectedCategory}
                            placeholder='Work Codes'
                            value={workCodes.filter((i) => i.value === formik.values.work_code_id)}
                            onChange={({ value }) => {
                                const event = {
                                    target: {
                                        name: 'work_code_id',
                                        value,
                                    },
                                };
                                formik.handleChange(event);
                            }}
                            options={workCodes}
                        />
                        <ErrorMessage name='work_code_id' />
                    </Col>
                    <Col className='mt-1' md={6} sm={6}>
                        <Label>Editor</Label>
                        <Select
                            placeholder='Editor'
                            value={employees.filter((i) => i.value === formik.values.editor_id)}
                            onChange={({ value }) => {
                                const event = {
                                    target: {
                                        name: 'editor_id',
                                        value,
                                    },
                                };
                                formik.handleChange(event);
                                setEmployeeLocation(value);
                            }}
                            options={employees}
                        />
                        <ErrorMessage name='editor_id' />
                    </Col>
                </Row>
                <Button className='mt-2' color='primary' onClick={() => formik.handleSubmit()}>
                    Edit Task
                </Button>
            </ModalBody>
        </Modal>
    );
};

export default EditTaskModal;
