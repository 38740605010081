import { Card, CardBody, CardText, Badge } from 'reactstrap';

const ProfileAbout = ({ user }) => {
  if (!user) return null;

  const Tags = ({ label, value }) => {
    return (
      <>
        <li className='mb-75'>
          <span className='fw-bolder me-25 d-flex justify-content-between'>{label}:</span>
          <span>{value}</span>
        </li>
      </>
    );
  };

  return (
    <Card>
      <CardBody>
        <CardText tag='h4'> About</CardText>
        <div classNam e='info-container'>
          <ul className='list-unstyled'>
            <Tags label='Full Name' value={user?.first_name + ' ' + user?.last_name} />
            <li className='mb-75'>
              <span className='fw-bolder me-25'>Status:</span>
              <Badge pill color={user?.is_active ? 'success' : 'danger'}>
                {user?.is_active ? 'Active' : 'Inactive'}
              </Badge>
            </li>
            <Tags label='Role' value={user?.role_name} />
            <Tags label='Position' value={user?.employee_position_name} />
            <Tags label='Country' value={user?.country_name} />
          </ul>
        </div>

        <div className='info-container'>
          <ul className='list-unstyled'>
            <Tags label='Contact' value={user?.phone_number} />
          </ul>
        </div>
      </CardBody>
    </Card>
  );
};

export default ProfileAbout;
